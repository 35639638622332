<template>
  <div class="filter-bar p-2" style="text-align: start">
    <b-navbar toggleable="xl">
      <b-navbar-toggle target="filter-collapse"></b-navbar-toggle>
      <b-collapse id="filter-collapse" is-nav>
        <div>
          <!-- <div class="d-flex justify-content-between">
            <button @click="reset()" class="filter-btn reset">Reset</button>
            <div class="settings-cog-box">
              <b-icon-gear-wide-connected
                v-b-modal="'addWidgets'"
                class="h3 my-auto"
              ></b-icon-gear-wide-connected>
            </div>
          </div> -->
          <div class="d-flex flex-wrap align-items-center">
            <button @click="reset()" class="filter-btn reset mr-2 mt-4">Reset</button>
            <b-form-group label="Select Company" class="mb-0 mr-2">
              <b-form-select
                :options="companies"
                text-field="name"
                value-field="id"
                v-model="myFilters.company_id"
                plain
                class="select company"
                style="margin-left: 0 !important;"
              >
              </b-form-select>
            </b-form-group>
            <b-form-group label="Select Year" class="mb-0 mr-2">
              <b-form-select
                :options="years"
                v-model="startYear"
                plain
                required
                class="select"
                style="margin-left: 0 !important;"
              >
                <template #first>
                  <b-form-select-option :value="null">Select Year</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Start Period" class="mb-0 mr-2">
              <b-form-select
                :options="periods"
                v-model="startPeriod"
                plain
                class="select"
                style="margin-left: 0 !important;"
              >
                <template #first>
                  <b-form-select-option :value="null">Start Period</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>

            <b-form-group label="End Period" class="mb-0 mr-2">
              <b-form-select
                :options="periods"
                v-model="endPeriod"
                plain
                class="select"
                style="margin-left: 0 !important;"
              >
                <template #first>
                  <b-form-select-option :value="null">End Period</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <div class="filter-btn-group mt-4">
              <button
                class="filter-btn left"
                :class="myFilters.period == 'month' ? 'selected' : ''"
                @click="myFilters.period = 'month'"
              >
                Month
              </button>
              <button
                class="filter-btn right"
                :class="myFilters.period == 'quarter' ? 'selected' : ''"
                @click="myFilters.period = 'quarter'"
              >
                Quarter
              </button>
            </div>

            <div class="filter-btn-group mt-4">
              <button
                class="filter-btn left"
                :class="myFilters.type == 'all' ? 'selected' : ''"
                @click="myFilters.type = 'all'"
              >
                All
              </button>
              <button
                class="filter-btn right"
                :class="myFilters.type == 'direct' ? 'selected' : ''"
                @click="myFilters.type = 'direct'"
              >
                Direct
              </button>
            </div>

            <div class="filter-btn-group mt-4 mr-2">
              <button
                class="filter-btn left"
                :class="myFilters.display == 'percent' ? 'selected' : ''"
                @click="myFilters.display = 'percent'"
              >
                %
              </button>
              <button
                class="filter-btn right"
                :class="myFilters.display == 'money' ? 'selected' : ''"
                @click="myFilters.display = 'money'"
              >
                $
              </button>
            </div>
            <!-- <date-range-picker
              class="select daterange"
              opens="center"
              single-date-picker="range"
              :show-dropdowns="true"
              :auto-apply="true"
              v-model="dateRange"
              @update="updateDates()"
              :ranges="ranges"
              @startSelection="selectionStep = 2"
              @finishSelection="selectionStep = 0"
              @toggle="($e) => { selectionStep = $e ? 1 : 0 }"
            >
              <template #input="picker">
                <span>
                  <span :style="selectionStep == 1 ? 'font-weight: bold;' : ''" class="mr-1">
                    {{ picker.startDate | date }}
                  </span>
                  to
                  <span :style="selectionStep == 2 ? 'font-weight: bold;' : ''" class="ml-1">
                    {{ picker.endDate | date }}
                  </span>
                </span>
              </template>
            </date-range-picker> -->
            <!-- <date-range-picker
              class="select daterange"
              opens="center"
              single-date-picker="range"
              :show-dropdowns="true"
              :auto-apply="true"
              v-model="compareRange"
              @update="updateCompareDates()"
              :ranges="ranges"
              @startSelection="compSelectionStep = 2"
              @finishSelection="compSelectionStep = 0"
              @toggle="($e) => { compSelectionStep = $e ? 1 : 0 }"
            >
              <template #input="picker">
                <span v-if="compSelectionStep > 0 || picker.startDate">
                  <span :style="compSelectionStep == 1 ? 'font-weight: bold;' : ''" class="mr-1">
                    {{ picker.startDate | date }}
                  </span>
                  to
                  <span :style="compSelectionStep == 2 ? 'font-weight: bold;' : ''" class="ml-1">
                    {{ picker.endDate | date }}
                  </span>
                </span>
                <span v-else>
                  Compare to date range
                </span>
              </template>
              <template #footer>
                <b-button @click="clearCompare" v-if="compareRange.startDate" block variant="danger" size="sm">Clear</b-button>
              </template>
            </date-range-picker> -->
            <b-form-group label="Compare Year" class="mb-0 mr-2">
              <b-form-select
                :options="years"
                v-model="compareYear"
                plain
                required
                class="select"
                style="margin-left: 0 !important;"
              >
                <template #first>
                  <b-form-select-option :value="null">Compare Year</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Compare Start" class="mb-0 mr-2">
              <b-form-select
                :options="periods"
                v-model="compareStartPeriod"
                plain
                class="select"
                style="margin-left: 0 !important;"
              >
                <template #first>
                  <b-form-select-option :value="null">Compare Start</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Compare End" class="mb-0 mr-2">
              <b-form-select
                :options="periods"
                v-model="compareEndPeriod"
                plain
                class="select"
                style="margin-left: 0 !important;"
              >
                <template #first>
                  <b-form-select-option :value="null">Compare End Period</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <div class="settings-cog-box">
              <b-icon-gear-wide-connected
                v-b-modal="'addWidgets'"
                class="h3 my-auto"
              ></b-icon-gear-wide-connected>
            </div>
          </div>
          <b-modal
            id="addWidgets"
            @ok="handleSave"
            @show="widgetsToAdd = []"
            @hidden="widgetsToAdd = []"
            title="Add Widgets"
            no-close-on-backdrop
            no-close-on-esc
            ok-title="Save"
          >
            <report-selector
              :widgets="widgets"
              @update="newWidgets"
            ></report-selector>
          </b-modal>
        </div>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import ReportSelector from '@/components/dashboard/ReportSelector'
// import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import DashboardLayoutService from '@/services/DashboardLayoutService'
import UserService from '@/services/UserService'
import moment from 'moment'
import AdminService from '@/services/AdminService'

export default {
  components: {
    // DateRangePicker,
    ReportSelector
  },
  props: {
    filters: {
      type: Object,
      default: null
    },
    widgets: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
      companies: [],
      compareTo: [
        { text: 'Last Period', value: 'period' },
        { text: 'Last Year', value: 'year' }
      ],
      startYear: null,
      startPeriod: null,
      endPeriod: null,
      compareYear: null,
      compareStartPeriod: null,
      compareEndPeriod: null,
      dateRange: {},
      compareRange: {},
      widgetsToAdd: [],
      myFilters: {},
      selectionStep: 0,
      compSelectionStep: 0,
      ranges: null,
      years: this.generateYears(),
      periods: [
        'Q1',
        'Q2',
        'Q3',
        'Q4',
        {
          label: 'Months',
          options: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ]
        }
      ]
    }
  },
  watch: {
    /**
     * Set our local filters when the parent is loaded properly.
     * All we're really doing here is tricking eslint into
     * thinking we are not mutating props. In JS when you do
     * object = object, it assigns it by reference, so we
     * really are mutating the props
     */
    filters: function (n, o) {
      if (n && n !== this.myFilters) {
        this.myFilters = n
        this.setDates(this.myFilters)
      }
    },
    startYear (newVal) {
      this.tryEmitDates()
    },
    startPeriod (newVal) {
      this.tryEmitDates()
    },
    endPeriod (newVal) {
      this.tryEmitDates()
    },
    compareYear (newVal) {
      this.tryEmitCompareDates()
    },
    compareStartPeriod (newVal) {
      this.tryEmitCompareDates()
    },
    compareEndPeriod (newVal) {
      this.tryEmitCompareDates()
    }
  },
  mounted () {
    // should we really be calling the service to retrieve these again here?
    // this.setDates()
    this.getCompanies()

    this.setRanges()
  },
  methods: {
    generateYears () {
      const currentYear = moment().year()
      return Array.from({ length: 10 }, (_, i) => currentYear - i).reverse()
    },
    getDateFromPeriod (year, period, type) {
      if (!period || !year) return null
      if (period.startsWith('Q')) {
        const quarterNumber = parseInt(period.charAt(1))
        const month = (quarterNumber - 1) * 3
        return this.getQuarterDate(year, month, type)
      } else {
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const monthIndex = monthNames.indexOf(period)
        return this.getMonthDate(year, monthIndex, type)
      }
    },
    getMonthDate (year, month, type) {
      const date = new Date(year, month)
      return type === 'start' ? new Date(date.getFullYear(), date.getMonth(), 1) : new Date(date.getFullYear(), date.getMonth() + 1, 0)
    },
    getQuarterDate (year, startMonth, type) {
      return type === 'start' ? new Date(year, startMonth, 1) : new Date(year, startMonth + 3, 0)
    },
    tryEmitDates () {
      if (this.startYear && this.startPeriod && this.endPeriod) {
        this.calculateDates()
        this.updateDates()
      }
    },
    tryEmitCompareDates () {
      if (this.compareYear && this.compareStartPeriod && this.compareEndPeriod) {
        this.calculateCompareDates()
        this.updateCompareDates()
      } else {
        this.compareRange = {
          startDate: null,
          endDate: null
        }
        this.updateCompareDates()
      }
    },
    calculateDates () {
      this.dateRange = {
        startDate: this.getDateFromPeriod(this.startYear, this.startPeriod, 'start'),
        endDate: this.getDateFromPeriod(this.startYear, this.endPeriod, 'end')
      }
    },
    calculateCompareDates () {
      this.compareRange = {
        startDate: this.getDateFromPeriod(this.compareYear, this.compareStartPeriod, 'start'),
        endDate: this.getDateFromPeriod(this.compareYear, this.compareEndPeriod, 'end')
      }
    },
    setDates (filters) {
      if (filters && filters.startDate && filters.endDate) {
        this.dateRange = {
          startDate: moment(filters.startDate).format('YYYY-MM-DD') + ' 12:00:00',
          endDate: moment(filters.endDate).format('YYYY-MM-DD') + ' 12:00:00'
        }
        this.startYear = moment(filters.startDate).year()
        const period = this.determinePeriodOrQuarter(filters.startDate, filters.endDate)
        this.startPeriod = period.startPeriod
        this.endPeriod = period.endPeriod
      } else {
        this.dateRange = {
          startDate: null,
          endDate: null
        }
        this.startYear = null
        this.startPeriod = null
        this.endPeriod = null
      }

      if (filters && filters.comparestart && filters.compareend) {
        this.compareRange = {
          startDate: moment(filters.comparestart).format('YYYY-MM-DD') + ' 12:00:00',
          endDate: moment(filters.compareend).format('YYYY-MM-DD') + ' 12:00:00'
        }
        this.compareYear = moment(filters.comparestart).year()
        const comparePeriod = this.determinePeriodOrQuarter(filters.comparestart, filters.compareend)
        this.compareStartPeriod = comparePeriod.startPeriod
        this.compareEndPeriod = comparePeriod.endPeriod
      } else {
        this.compareRange = {
          startDate: null,
          endDate: null
        }
        this.compareYear = null
        this.compareStartPeriod = null
        this.compareEndPeriod = null
      }
    },

    determinePeriodOrQuarter (startDate, endDate) {
      const start = moment(startDate)
      const end = moment(endDate)
      const startMonth = start.month()
      const endMonth = end.month()
      const isStartOfQuarter = (start.date() === 1) && ([0, 3, 6, 9].includes(startMonth))
      const isEndOfQuarter = (end.date() === end.clone().endOf('month').date()) && ([2, 5, 8, 11].includes(endMonth))
      if (start.year() === end.year() && isStartOfQuarter && isEndOfQuarter) {
        // Same quarter
        const quarterStart = 'Q' + (Math.floor(startMonth / 3) + 1)
        const quarterEnd = 'Q' + (Math.floor(endMonth / 3) + 1)
        return { startPeriod: quarterStart, endPeriod: quarterEnd }
      } else {
        // Different months and different quarters
        return { startPeriod: start.format('MMM'), endPeriod: end.format('MMM') }
      }
    },

    async getCompanies () {
      if (UserService.isAdmin()) {
        this.companies = await AdminService.getCompanies()
      } else {
        this.companies = await UserService.getCompanies()
      }
    },

    setRanges () {
      const thisQuarter = [
        new Date(moment().startOf('quarter').hours(12).toISOString()),
        new Date(moment().endOf('quarter').hours(12).toISOString())
      ]
      const lastQuarter = [
        new Date(moment().subtract(1, 'quarter').startOf('quarter').hours(12).toISOString()),
        new Date(moment().subtract(1, 'quarter').endOf('quarter').hours(12).toISOString())
      ]

      const thisMonth = [
        new Date(moment().startOf('month').hours(12).toISOString()),
        new Date(moment().endOf('month').hours(12).toISOString())
      ]
      const lastMonth = [
        new Date(moment().subtract(1, 'month').startOf('month').hours(12).toISOString()),
        new Date(moment().subtract(1, 'month').endOf('month').hours(12).toISOString())
      ]

      const YTD = [
        new Date(moment().startOf('year').hours(12).toISOString()),
        new Date(moment().hours(12).toISOString())
      ]
      const lastYear = [
        new Date(moment().subtract(1, 'year').startOf('year').hours(12).toISOString()),
        new Date(moment().subtract(1, 'year').endOf('year').hours(12).toISOString())
      ]
      this.ranges = {
        'Current Month': thisMonth,
        'Previous Month': lastMonth,
        'Current Quarter': thisQuarter,
        'Previous Quarter': lastQuarter,
        'Year To Date': YTD,
        'Previous Year': lastYear
      }
    },

    reset () {
      this.$emit('reset')
      this.$nextTick(() => {
        this.setDates()
      })
    },

    updateDates () {
      this.$emit('dateFilterChange', this.dateRange)
    },

    updateCompareDates () {
      this.$emit('compareFilterChange', this.compareRange)
    },

    clearCompare () {
      this.compareRange = {
        startDate: null,
        endDate: null
      }
      this.updateCompareDates()
    },

    newWidgets (items) {
      this.widgetsToAdd = items
    },

    handleSave (bvModalEvt) {
      bvModalEvt.preventDefault()
      DashboardLayoutService.getLayout(this.filters).then(
        (response) => {
          const currentLayout = response.data.info
          Array.prototype.unshift.apply(currentLayout, this.widgetsToAdd)
          DashboardLayoutService.save({ layout: currentLayout }).then(
            (response) => {
              this.$nextTick(() => {
                this.$emit('refresh')
                this.$bvModal.hide('addWidgets')
              })
            }
          )
        }
      )
    }
  },
  filters: {
    date (val) {
      if (val === null) {
        return 'yyyy/mm/dd'
      }
      return moment(val).format('YYYY-MM-DD')
    }
  }
}
</script>
